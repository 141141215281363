<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-col cols="24">
        <v-row>
          <v-text-field
            v-model="objEditar.summary"
            class="purple-input"
            :label="$t('TextosContratoForm.summary')"
          />
        </v-row>
        <v-row>
          <v-select 
            v-model="objEditar.context"
            full-width
            :placeholder="$i18n.t('TextosContratoForm.context')"
            clearable
            :items="items"
          />
        </v-row>
        <v-row class="editor">
          <label 
            class="editor_label"
            for="ckeditor_contrato"
          >{{ $i18n.t('TextosContratoForm.text') }}</label>
          <ckeditor
            id="ckeditor_contrato"
            v-model="objEditar.text"
            :editor="editor"
            :config="editorConfig"
            style="width: 100%;"
          />
        </v-row>
      </v-col>
    </v-container>
  </crud-form>
</template>
<script>
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';
  import TextosContratoService from "@/services/TextosContratoService";

  export default {
    data: () => ({
      objPadrao: {
        summary: "",
        text: "",
        context: null,
        id: null
      },
      objEditar: {
        summary: "",
        text: "",
        context: null,
        id: null
      },
      isEditting: true,
      editor: ClassicEditor,
      editorConfig: {
        language: 'pt-br'
      },
      items: [
        {
          text: 'Evento',
          value: 'evento'
        },
        {
          text: 'Itens',
          value: 'itens'
        },
        {
          text: 'Pagamento',
          value: 'pagamento'
        },
      ]
    }),
    computed: {
      mensagemPerguntaRemocao() {
        return this.$i18n.t("padrao_deseja_remover_registro");
      },
      tituloPagina() {
        return this.$i18n.t("TextosContratoForm.tituloPagina");
      }
    },
    created() {
      this.$root.$emit("alteraMostraPesquisa", false);

      let id = this.$route.params.id;
      if (id) {
        this.editar(id);
      } else {
        this.isEditting = false;
        this.objEditar = this.objPadrao;
      }
    },
    methods: {
      saveEventHandler() {
        if (this.isEditting) {
          this.atualizar(this.objEditar);
        } else {
          this.inserir(this.objEditar);
        }
      },
      atualizar(registro) {
        try {
          TextosContratoService.update(registro).then(response => {
            if (!response) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "Deu algum erro,\nResposta do Servidor: null "
              });
            } else if (response.error) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "" + response.error
              });
            } else {
              this.$root.$emit("mostra_dialogo_info", {
                dialogoTitulo: this.$t("padrao_sucesso"),
                dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
              });
              this.closeDialog();
            }
          });
        } catch (error) {
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao:
              "Não foi possível concluir a atualização, tente mais tarde\n" +
              error
          });
        }
      },
      closeDialog() {
        this.$router.push("/textos-contrato");
      },
      remover() {
        const textoContrato = this.objEditar;
        try {
          TextosContratoService.delete(textoContrato).then(response => {
            this.$root.$emit("mostra_dialogo_info", {
              dialogoTitulo: this.$t("padrao_sucesso"),
              dialogoDescricao: this.$t("padrao_registro_removido_sucesso")
            });
            this.closeDialog();
          });
        } catch (error) {
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao:
              "Ocorreu um erro ao remover o texto de contrato:\n ->" + error
          });
        }
      },
      inserir(textoContrato, manterNestaTela) {
        try {
          TextosContratoService.insert(textoContrato)
            .then(response => {
              if (!response) {
                this.$root.$emit("mostra_dialogo_erro", {
                  dialogoDescricao: "Deu algum erro,\nResposta do Servidor: null "
                });
              } else if (response.error) {
                this.$root.$emit("mostra_dialogo_erro", {
                  dialogoDescricao: "" + response.error
                });
              } else {
                this.$root.$emit("mostra_dialogo_info", {
                  dialogoTitulo: this.$t("padrao_sucesso"),
                  dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
                });
                if (manterNestaTela) {
                  this.editar(textoContrato.data.id);
                } else {
                  this.closeDialog();
                }
              }
            })
            .catch(error => {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao:
                  "Ocorreu um erro ao inserir o texto de contrato:\n ->" + error
              });
            });
        } catch (error) {
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao:
              "Ocorreu um erro ao inserir o texto de contrato:\n ->" + error
          });
        }
      },
      editar(id) {
        TextosContratoService.findById({ id })
          .then(textoContrato => {
            if (textoContrato.id) {
              this.objEditar = textoContrato;
              this.isEditting = true;
            } else if (textoContrato.data.id) {
              this.objEditar = textoContrato.data;
              this.isEditting = true;
            } else {
              this.isEditting = false;
            }
          })
          .catch(() => {
            this.isEditting = false;
          });
      }
    }
  };
</script>
<style>
  .ck.ck-editor {
    width: 100%;
  }
  .ck-editor__editable_inline {
    min-height: 250px;
  }
  .editor {
    margin-top: 30px;
  }
  .editor label {
    color: #A3A3A3;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 7px;
  }
</style>
